#contactus {
  background-image: url(https://cdn.explorug.com/website/explorug/Before-After-min.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

.realistic-image {
  position: relative;
  width: 100%;
  height: 100vh;
  /* height: 100%; */
}

.realistic-para {
  display: none;
}

.realistic-text-one {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.5);
  top: 30%;
  left: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  border-radius: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
}
/* .realistic-text-two {
  position: absolute;
  text-align: left;
  background-color: rgba(51, 50, 50, 0.4);
  top: 50%;
  transform: translateY(-50%);
  padding: 0.6vw 0.9vw;
  border-radius: 0 0.2vw 0.2vw 0;
  display: inline-block;
  left: 0;
} */

.realistic-text-one-tablet {
  display: none;
}

.realistic-email {
  font-weight: 600;
  border: 1px solid white;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
}

.realistic-para1 {
  padding-top: 1rem;
  padding-bottom: 5rem;
}

.realistic-para2 {
  padding-bottom: inherit;
}

.realistic-text-two {
  position: absolute;
  top: 43%;
  left: 0;
  bottom: auto;
  background-color: rgb(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
.softwarerug {
  padding-bottom: 1.5rem;
}

@media (min-width: 400px) {
  .realistic-text-two {
    display: block;
  }
}

@media (min-width: 400px) {
  .realistic-text-one {
    display: block;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .realistic-text-two {
    font-size: 0.8rem;
    top: 44%;
  }

  .realistic-text-one {
    left: 32%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sectionfooter {
    font-size: 0.9rem;
  }
  .visualizationfromactualrug {
    padding-top: 0.5rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .realistic-text-one {
    display: none;
  }

  .realistic-para1-tablet {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
  }

  .realistic-text-one-tablet {
    display: block;
    padding-left: 3rem;
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    left: 33%;
    top: 40%;
    font-size: 0.8rem;

    border-radius: 1rem;
  }

  .realistic-email-tablet {
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid white;
    border-radius: 0.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-decoration: none;
  }

  .realistic-text-one {
    top: 37%;
    border-radius: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .realistic-email {
    border-radius: 1.5rem;
  }

  .realistic-text-two {
    font-size: 0.7rem;
    padding-bottom: 1rem;

    top: 44%;
  }
  .visualizationfromactualrug {
    padding-top: 0.6rem;
  }
}

@media only screen and (max-width: 767px) and (min-width: 425px) {
  .sellyourrug {
    font-size: 1.1rem;
  }

  .realistic-para1 {
    font-size: 14px;
  }

  .realistic-image {
    top: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .realistic-text-one {
    display: block;
    width: 50%;
    /* height: 50%; */
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.7);
    border-radius: 1rem;
    padding-bottom: 2rem;
  }

  .realistic-para1 {
    font-size: 14px;
    width: 100%;
    padding-bottom: 1rem;
  }

  .realistic-text-two {
    font-size: 0.8rem;
    display: block;
    padding-bottom: 1rem;
    top: 43%;
  }

  .realistic-email {
    font-weight: 500;

    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 15px;
    text-decoration: none;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .visualizationfromactualrug {
    padding-top: 0.5rem;
  }
}

@media only screen and (max-width: 424px) and (min-width: 375px) {
  .sellyourrug {
    width: 120%;
    font-size: 1.1rem;
  }

  .realistic-image {
    top: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .realistic-text-one {
    display: block;
    width: 50%;

    z-index: 1;
    background-color: rgb(0, 0, 0, 0.7);
    padding-left: 2rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
  }

  .realistic-para1 {
    font-size: 0.9rem;
    width: 120%;
  }

  .realistic-text-two {
    font-size: 0.8rem;
    display: block;
    top: 44%;
  }

  .realistic-email {
    margin-left: 1rem;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
