.explorugvideosection {
  /* margin-bottom: 6rem; */
  /* margin-bottom: 10rem; */
  /* margin-top: 30rem; */
  /* margin-top: 14rem; */
  display: flex;
  justify-content: center;
}
/* .learnmoreclick {
  padding-top: 9rem;
} */
.explorugvideo {
  width: 1000px;
  /* height: 549px; */
  height: 545px;

  display: flex;
  justify-content: center;
}
.explorugvideo iframe {
  /* width: 100%; */
  /* width: 100vw; */
  width: 1000px;
  height: 100%;
}
@media only screen and (min-width: 1500px) and (max-width: 1920px) {
  /* .explorugvideo {
    height: 690px;
    width: 1252px;
  } */

  .explorugvideo {
    height: 550px;
    width: 1000px;
  }
}
@media only screen and (min-width: 1121px) and (max-width: 1400px) {
  .explorugvideo {
    width: 796px;
    height: 434px;
  }
}

@media only screen and (min-width: 970px) and (max-width: 1120px) {
  .explorugvideo {
    width: 1000px;
    height: 549px;
  }
}

@media only screen and (min-width: 909px) and (max-width: 960px) {
  .explorugvideo {
    width: 1000px;
    height: 511px;
  }
}
@media only screen and (max-width: 1120px) {
  .section-one-text {
    flex-direction: column;
  }
}

@media only screen and (min-width: 824px) and (max-width: 908px) {
  .explorugvideo {
    width: 820px;
    height: 450px;
  }
}
@media only screen and (min-width: 723px) and (max-width: 823px) {
  .explorugvideo {
    width: 1000px;
    height: 454px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 722px) {
  /* .explorugvideo {
    width: 104vw;
    height: 407px;
  } */
  .explorugvideo {
    width: 1000px;
    height: 406px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 599px) {
  .explorugvideo {
    width: 1000px;
    height: 338px;
  }

  /* .explorugvideo {
    width: 106vw;
    height: 39vh;
  } */
}

@media only screen and (min-width: 425px) and (max-width: 499px) {
  .explorugvideo {
    width: 1000px;
    height: 281px;
  }

  /* .explorugvideo {
    width: 105vw;
    height: 32vh;
  } */
}
@media only screen and (min-width: 370px) and (max-width: 425px) {
  .explorugvideo {
    width: 1000px;
    height: 239px;
  }
  /* .explorugvideo {
    width: 106vw;
    height: 27vh;
  } */
}
@media only screen and (max-width: 369px) {
  .explorugvideo {
    width: 380px;
    height: 200px;
  }
}
