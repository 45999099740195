 .rejuvenateyourshowroom-wrapper { 
  background-image: url("https://cdn.explorug.com/website/explorug/TV-Screen.jpg");
  height: 60rem;
  background-size: cover;
  background-position: right;
  position: relative;
} 
/* .rejuvenateyourshowroom-wrapper >img{
  display: none;
} */
.hotspots{
  position: absolute;
  left:67%;
  width:100%;
  height:52%;
  visibility: visible;

}


.rejuvenateyourshowroom-text-one {
  position: absolute;
  top: 40%;
  left: -12%;
  text-align-last: right;
}

.rejuvenateyourshowroom-text-two {
  position: absolute;
  top: 60%;
  right: 4%;
  text-align-last: right;
}
@media (max-width:1440px){
  .hotspots{
   left:61%;
   height:52%;
   top:4%; 
  }
}

@media (max-width:1024px){
  .hotspots{
    left:45%;
    height:50%;
    top:4%;
  }
}

@media(max-width:768px){
  .hotspots{
    left:39%;
    height: 50%;
    top:5%;
  }
}
/* @media(max-width:406px){
  .rejuvenate-para1{
    font-size: 0.7rem;
  }
  
} */
@media(max-width:425px){
  .rejuvenateyourshowroom-wrapper{
    height: 53rem;
  }
  .hotspots{
    left:0;
    height:49%;
    top:4%;
  }
  
}


@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .rejuvenateyourshowroom-text-one {
    margin-left: -6rem;
    left: 0%;
  }
  .rejuvenateyourshowroom-text-one p {
    font-size: 0.8rem;
    margin-left: -6rem;
  }
  .rejuvenateyourshowroom-text-two {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* .myroom-para1-tablet {
    font-size: 0.8rem;
  } */
  .myroom-para1-tablet {
    font-size: 0.9rem;
  }
  .rejuvenateyourshowroom-wrapper {
    /* height: 82rem; */
    height: 50rem;

    /* margin-top: 20rem; */
  }
  .h1-mobile,
  .rejuvenate-h1-mobile {
    color: #00d092;
  }
  /* .rejuvenateyourshowroom-text-two {
    font-size: 0.6rem;
  } */
  .rejuvenateyourshowroom-text-two {
    font-size: 0.7rem;
  }
  .rejuvenateyourshowroom-text-one {
    top: 45%;
  }
  .rejuvenate-para1 {
    display: none;
  }
}
@media only screen and (max-width: 767px) and (min-width: 375px) {
  .rejuvenateyourshowroom-text-two {
    display: none;
  }
  .rejuvenateyourshowroom-text-one {
    text-align-last: center;
    left: 0;
  }
  .rejuvenate-h1-mobile {
    color: #00d092;
    font-size: 1.2rem;
    padding-bottom: 0.8rem;
    font-weight: bold;
  }
  .rejuvenate-para1 {
    font-size: 0.7rem;
  }
  .rejuvenateyourshowroom-text-one {
    top: 60%;
  }
}
