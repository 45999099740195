.promotionalModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 22;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.promotionalContainer {
  width: 46.875rem;
  height: 90vh;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 65465;
  overflow: hidden;
  padding: 1.25;
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
}

.promotionalContainer.isMobile {
  width: 95%;
  height: 75vh;
}

.at-login-screen {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.promotionalContainer .list {
  height: 19.25rem;
}

.promotionalContainer .list.isMobile {
  height: 257px;
}

.promotionalContainer .list li {
  list-style-type: none;

  width: 27.938rem;
  height: 19.25rem;
  position: absolute;
  left: 50%;
  margin-left: -13.938rem;
  border-radius: 10px;
}

.promotionalContainer .list.isMobile li {
  width: 209px;
  height: 257px;
  margin-left: 104px;
}

.promotionalContainer .list li {
  transition: transform 1s;
}

.promotionalContainer .list li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.promotionalContainer .hide {
  transform: translateX(-100%);
  border-radius: 10px;
  opacity: 0;
}

.promotionalContainer .prev {
  transform: translateX(-23.438rem) scale(0.525);
}

.promotionalContainer .next {
  transform: translateX(23.438rem) scale(0.525);
}

.promotionalContainer .act {
  z-index: 1;
}

.promotionalContainer .new-next {
  transform: translateX(39.375rem) scale(0.2512);
  opacity: 0;
}

.promotionalContainer .old-prev {
  transform: translateX(-39.375rem) scale(0.2512);
  opacity: 0;
}
.promotionalContainer .carousel {
  /* margin-top: 3.5rem; */
  position: relative;
  width: 100%;
  align-self: center;
}

.promotionalContainer .carousel::before {
  content: "";
  display: block;
  width: 100%;
  height: max-content;
}

.promotionalContainer .controller-button {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 50%;

  transform: translate(0, -50%);
  width: 100%;
  z-index: 2;
}

.promotionalContainer .controller-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: rem;
  border-radius: 50%;
  border: 2px solid white;

  background-color: rgba(217, 217, 217, 0.2);
  cursor: pointer;
}

.promotionalContainer .logo {
  width: 31.813rem;
  justify-self: center;
}

.promotionalContainer .logo.isMobile {
  width: 14.875rem;
  justify-self: center;
}

.promotionalContainer .logo img {
  width: 100%;
  height: 100%;
}

.promotionalContainer .about {
  /* margin-top: 6rem; */
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 2.563rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.promotionalContainer .about.isMobile {
  width: 100%;
  height: fit-content;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.promotionalContainer .description {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
}

.promotionalContainer .contact {
  /* margin-top: 3.5rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 0.8rem;
}

.promotionalContainer .contact.isMobile {
  width: 100%;
}

.promotionalContainer .upgrade {
  font-weight: 600;
  font-size: 26px;
  line-height: 15px;
  text-align: center;
  align-self: self-end;
}

.promotionalContainer .upgrade.isMobile {
  width: 100% !important;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.625rem;
  align-self: center;
}

.promotionalContainer .contact button {
  /* margin-top: 1.2rem; */
  background-color: #f05223;
  width: 32.938rem;
  height: 3.688rem;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.813rem;
  text-align: center;
  cursor: pointer;
}

.promotionalContainer > .contact.isMobile button {
  width: 245px;
  height: 49px;
}

.promotionalContainer > .contact div {
  /* margin-top: 1rem; */
  color: #f05223;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
}

.promotionalContainer > .contact div u {
  cursor: pointer;
}

.promotionalContainer > .contact.isMobile div {
  font-size: 12px;
}

.promotionalContainer > .icon-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  font-size: 30px;
  color: black;
}
