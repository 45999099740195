.modal-content {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-header {
  border-bottom: none !important;
}

.btn-close {
  color: white !important;
  background-color: red;
}

.modal-body {
  padding-left: 2rem;
  padding-right: 2rem;
}
.modal-fullscreen .modal-body {
  overflow-y: hidden;
}

.modal-paragraph1,
.modal-paragraph2 {
  font-size: 1vw;
}

.modal-heading {
  padding-bottom: 2rem;
}
.modal-form {
  padding-top: 4rem;
  padding-left: 20rem;
  padding-right: 20rem;
}

.form-group {
  margin-bottom: 1rem;
}
.send-button {
  background-color: #00d092 !important;
  border: none;
}
.send-button:hover {
  background-color: #03e29f !important;
}
.modal-footer {
  border-top: none;
}
.modalsuccess-paragraph {
  font-size: 2vw;
}
.success-close {
  position: absolute;
  right: 2rem;
}
.modal-success .modal-dialog {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
a.requestademoform-link {
  color: #03e29f;
  text-decoration: none;
}
a.requestademoform-email {
  color: white;
  text-decoration: none;
}
.requestademoform-link {
  font-weight: bold;
}

.navbarcontainer {
  display: flex;
  justify-content: space-between;
  background: url("https://explorug.net/new/css/Op-65.png");

  /* background-color: rgba(51, 50, 50, 0.65); */
}
.headercontainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
}
.humbergermenucontainer,
.loginformenu {
  display: none;
}
.navcontents {
  color: white;
  padding-right: 1rem;
}
.navcontents ul {
  display: flex;
  list-style: none;
}
.navcontents ul li {
  padding-left: 2rem;
  padding-top: 1rem;
}
.explorug-logo {
  padding-left: 2rem;
}
.explorug-logo-image {
  width: 80%;
}
.tryexplorugnow {
  background-color: #00d092 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  color: white;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.menuandlogo {
  display: flex;
  justify-content: space-around;
}
.collapsenavcontent {
  color: white;
}
.logintext {
  color: white;
  text-decoration: none;
}
.logintext:hover {
  text-decoration: none;
  color: white;
}
.tel-box {
  display: flex;
}
.select-box {
  width: 25%;
}
select {
  width: 100%;
}
select option {
  padding: 20px !important;
}

@media (max-width: 1024px) {
  .form-group {
    width: 60vw;
    margin-left: -7rem;
  }
}
@media (max-width: 991px) {
  .form-group {
    width: 80vw;
  }
}

@media (max-width: 425px) {
  .modal-heading {
    font-size: 2rem;
  }
  .modal-paragraph1,
  .modal-paragraph2 {
    font-size: 0.9rem !important;
  }
  .form-group {
    width: 60vw;
    margin-left: -17rem;
  }
  .modal-fullscreen .modal-body {
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .modal-heading {
    font-size: 2rem;
  }
  .modal-paragraph1,
  .modal-paragraph2 {
    font-size: 0.9rem !important;
  }
  .form-group {
    margin-left: -15rem;
  }
}

@media only screen and (min-width: 739px) and (max-width: 777px) {
  .navcontents ul li {
    font-size: 0.9rem;
  }
}
@media only screen and (min-width: 778px) and (max-width: 786px) {
  .navcontents ul li {
    font-size: 0.8rem;
  }
  .tryexplorugnow {
    padding-bottom: 1.4rem;
  }
}
@media only screen and (min-width: 747px) and (max-width: 777px) {
  .tryexplorugnow {
    padding-bottom: 1.2rem;
  }
}
@media only screen and (min-width: 709px) and (max-width: 717px) {
  .navcontents ul li {
    font-size: 0.7rem;
  }
  .tryexplorugnow {
    padding-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 718px) and (max-width: 746px) {
  .navcontents ul li {
    font-size: 0.8rem;
  }
  .tryexplorugnow {
    padding-bottom: 1.4rem;
  }
}

@media only screen and (min-width: 747px) and (max-width: 754px) {
  .navcontents ul li {
    font-size: 0.8rem;
  }
  .tryexplorugnow {
    padding-bottom: 1.4rem;
  }
}
@media only screen and (min-width: 787px) and (max-width: 793px) {
  .navcontents ul li {
    font-size: 0.7rem;
  }
  .tryexplorugnow {
    padding-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 640px) and (max-width: 642px) {
  .navcontents ul li {
    font-size: 0.7rem;
    padding-left: 1rem;
  }
  .tryexplorugnow {
    padding-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 643px) and (max-width: 708px) {
  .navcontents ul li {
    font-size: 0.7rem;
    padding-left: 1rem;
  }
  .tryexplorugnow {
    padding-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 810px) {
  .collapsenavbarcontents {
    position: fixed;
    z-index: 9;
    width: 99vw;
    /* background-color: #242424;
    opacity: 0.9; */
    background: url("https://explorug.net/new/css/Op-65.png");
  }
  .loginformenu {
    display: flex;
    color: white;
    padding-top: 1.2rem;
    text-decoration: none;
  }
  .loginformenu:hover {
    color: white;
  }
  .navcontents {
    display: none;
  }
  .humbergermenucontainer {
    display: flex;
    padding: 1rem;
  }
  .icon {
    font-size: 2rem;
    color: white;
  }

  .collapsenavcontents ul li {
    list-style: none;
  }
  .collapsenavcontents {
    text-align: center;
  }
  .collapsedivtryexplorugnow {
    background-color: #00d092;
  }
  .collapsetryexplorugnow {
    text-decoration: none;
    color: white;
  }
  .collapsenavcontents div {
    border-top: 1px solid white;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .collapsemenuandlogo {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .collapseloginformenu {
    display: flex;
    color: white;

    text-decoration: none;
  }
  .collapseloginformenu:hover {
    color: white;
  }
  .crosscontainer {
    margin-left: 2rem;
    color: white;
  }
}
