.gray {
  text-decoration: none;
  color: #00d092;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 5vw;
  font-size: 1rem;
  border-bottom: 2px solid white;
  padding-bottom: 1rem;
}

.gray:hover {
  border-bottom: 2px solid #00d092;
  color: #ffff;
}

.myroomsection-wrapper {
  background-color: #242424;
  padding-top: 10rem;
  /* padding-top: 28rem; */
  /* padding-top: 34rem; */
  padding-bottom: 10rem;
}

.myroom-image {
  width: 130%;
}

.myroomsection-image-mobile {
  display: none;
}

.myroomsection-text {
  margin-top: 14rem;
  margin-left: auto;
  text-align: justify;
  text-align-last: right;
  margin-left: -6rem;
}

.myroomsection-image {
  margin-left: 6rem;
}

.myroom-para1-tablet {
  display: none;
}

@media (max-width: 406px) {
  .myroom-para1 {
    font-size: 0.7rem;
  }
}

/* @media only screen and (max-width: 1885px) and (min-width: 1190px) {
  .explorugvideo {
    width: 875px;
    height: 529px;
  }
} */
/* @media only screen and (max-width: 1397px) and (min-width: 1210px) {
  .explorugvideo {
    width: 800px;
    height: 500px;
  }
} */

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .myroomsection-text p {
    font-size: 0.8rem;
  }

  .myroomsection-text {
    margin-top: 6rem;
  }
  .myroom-image {
    width: 100%;
  }
  .myroomsection-wrapper {
    /* padding-top: 19rem; */
    padding-top: 10rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* .myroomsection-wrapper {
    padding-top: 30rem;
 
  } */

  .myroomsection-text {
    margin-top: 4rem;
    margin-left: -3rem;
  }

  /* .myroomsection-text p {
    font-size: 0.8rem;
  } */
  .myroomsection-text p {
    font-size: 1rem;
  }

  .myroomsection-image {
    margin-left: 0rem;
  }

  .myroom-para1 {
    display: none;
  }

  .myroom-image {
    width: 115%;
  }

  .myroom-para1-tablet {
    display: block;
  }

  .myroom-h1-laptop {
    color: #00d092;
  }
  .myroomsection-wrapper {
    padding-top: 10rem;
    /* padding-top: 18rem; */
  }
}

@media only screen and (max-width: 767px) and (min-width: 375px) {
  .myroom-h1-laptop {
    display: none;
  }
  .myroomsection-image {
    display: none;
  }
  .myroomsection-image-mobile {
    display: block;
    text-align: center;
  }
  .myroomsection-text {
    text-align-last: center;
    margin-left: 0rem;
    font-size: 0.8rem;
    margin-top: 1rem;
  }
  .myroom-h1-mobile {
    color: #00d092;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .gray {
    font-size: 0.8rem !important;
  }
  .myroom-para1 {
    padding-bottom: 1rem;
  }
  .myroomsection-wrapper {
    padding-top: 10rem;
  }
}
