.section-one-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.subtitle-mid {
  display: none;
}

.section-one-container {
  /* background-image: url(https://cdn.explorug.com/website/explorug/exploRUG-interface.jpg); */
  background-image: url(https://cdn.explorug.com/website/explorug/exploRUG-interface-gradient.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.wrappertitle {
  padding-left: 2rem;
}
/* iframe {
  border: 2px solid white;
} */

.section-one-text {
  position: absolute;
  top: 25%;
  /* top: 21%; */
  /* top: -11%; */
  left: 0;
  width: 100%;
  /* height: 100%; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* margin-top: 25rem; */
}

.sectionone-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.sectionone-learnmore-btn {
  background: none;
  border: 1px solid;
  color: white;
  border-color: white;
  border-radius: 1.6rem;
  padding-left: 4em;
  padding-right: 4em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 2vw;
  font-size: 1.4rem;
}

.subtitle {
  /* text-align: center; */
  font-size: 1vw;
  /* padding: 1vw; */
}

@media (max-width: 2561px) and (min-width: 1440px) {
  .sectionone-image {
    height: 100vh;
  }
}
/* @media only screen and (max-width: 1381px) {
  .section-one-text {
    top: 32%;
  }
} */

@media only screen and (max-width: 1400px) and (min-width: 900px) {
  .subtitle {
    font-size: 1.5vw;
  }
}
@media only screen and (max-width: 1120px) {
  .wrappertitle {
    text-align: center;
    order: 2;
    padding-left: 0 !important;
  }
}
@media only screen and (max-width: 1120px) and (min-width: 908px) {
  .section-one-text {
    /* top: 18%; */
    top: 14%;
  }
  .wrappertitle {
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 908px) and (min-width: 600px) {
  .section-one-text {
    /* top: 23%; */
    top: 15%;
  }
  .wrappertitle {
    padding-top: 3rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 823px) {
  .subtitle-mid {
    display: block;
    padding-top: 1rem;
  }
  .subtitle {
    display: none;
  }
  .wrappertitle {
    padding-top: 5rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* .subtitle {
    font-size: 0.8rem;
  } */
  .subtitle {
    font-size: 1rem;
  }

  .sectionone-learnmore-btn {
    border-radius: 0.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 767px) and (min-width: 375px) {
  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 13px;
  }
  .image-wrapper {
    height: 100vh;
  }
  .sectionone-image {
    height: 100%;
  }

  .sectionone-learnmore-btn {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
}
@media only screen and (max-width: 599px) {
  .wrappertitle {
    padding-top: 5rem;
  }
  .subtitle {
    display: none;
  }
  .subtitle-mid {
    padding-top: 1rem;
    display: block;
  }
  .section-one-text {
    /* top: 28%; */
    top: 25%;
  }
}
