.gray {
  text-decoration: none;
  color: #00d092;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 5vw;
  font-size: 1.2vw;
  border-bottom: 2px solid white;
  padding-bottom: 1rem;
}

.revolutionalize-wrapper {
  background-color: #242424;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.revolutionalize-text {
  text-align-last: right;
  margin-left: -7rem;
  padding-top: 7rem;
}
.revolutionalize-description {
  padding-left: 17rem;
}
.socialmedia-text {
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 2vw;
  font-size: 1.2vw;
}
.socialmediaicon {
  margin-left: 0.3rem;
}

.revolutionalize-image {
  margin-left: 7rem;
  /* padding-left: 4rem; */
}
/* .revolutionalize-img {
  width: 100%;
} */
.revolutionalize-text-mobile {
  display: none;
}
.revolutionalize-para-tablet {
  display: none;
}
/* .h1-mobile{
    display: none;
} */
.revolutionalize-h1-mobile {
  display: none;
}
.revolutionalize-para-lessthantablet {
  display: none;
}

@media only screen and (max-width: 1885px) and (min-width: 1440px) {
  .revolutionalize-img {
    max-width: 100%;
    height: auto;
  }
  .revolutionalize-description {
    padding-left: 2rem;
  }
  .revolutionalize-text {
    padding-top: 3rem;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  /* .revolutionalize-wrapper {
    padding-top: 19rem;
  } */
  .revolutionalize-text p {
    font-size: 0.8rem;
  }
  .revolutionalize-text {
    padding-top: 1rem;
  }
  .revolutionalize-img {
    width: 116%;
  }
  .revolutionalize-image {
    margin-left: 2rem;
  }
  .revolutionalize-description {
    padding-left: 0;
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 917px) {
  .revolutionalize-text-mobile {
    display: none;
  }
  .revolutionalize-wrapper {
    /* padding-top: 19rem; */

    overflow-x: hidden !important;
  }
  .revolutionalize-para-1 {
    display: block;
  }

  /* .revolutionalize-para-tablet {
    font-size: 1rem;
    display: block;
  } */
  .revolutionalize-h1-laptop {
    color: #00d092;
  }
  .revolutionalize-text {
    margin-left: -3rem;
    margin-top: -2rem;
    padding-top: 1rem;
  }
  .revolutionalize-img {
    width: 112%;
    margin-left: 1rem;
  }
  .revolutionalize-image {
    margin-left: 0rem;
  }
  .revolutionalize-description {
    padding-left: 4rem;
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 917px) and (min-width: 883px) {
  .revolutionalize-para-lessthantablet {
    display: block;
    font-size: 0.9rem;
  }

  .revolutionalize-para-1 {
    display: none;
  }
  .revolutionalize-h1-laptop {
    color: #00d092;
  }
  .revolutionalize-text {
    margin-left: -3rem;
    margin-top: -2rem;
    padding-top: 1rem;
  }
  .revolutionalize-img {
    width: 112%;
    margin-left: 1rem;
  }
  .revolutionalize-image {
    margin-left: 0rem;
  }
  .revolutionalize-description {
    padding-left: 4rem;
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 883px) and (min-width: 744px) {
  .revolutionalize-para-1 {
    display: none;
  }
  .revolutionalize-para-tablet {
    display: block;
  }
  .revolutionalize-h1-laptop {
    color: #00d092;
  }
  .revolutionalize-text {
    margin-left: -3rem;
    margin-top: -2rem;
    padding-top: 1rem;
  }
  .revolutionalize-img {
    width: 112%;
    margin-left: 1rem;
  }
  .revolutionalize-image {
    margin-left: 0rem;
  }
  .revolutionalize-description {
    padding-left: 4rem;
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 767px) and (min-width: 375px) {
  .revolutionalize-img {
    width: 100%;
  }
  .revolutionalize-description {
    padding: 0;
    font-size: 0.6rem;
  }
  /* .revolutionalize-wrapper {
    padding-top: 12rem;
  } */
  .revolutionalize-text {
    display: none;
  }
  .revolutionalize-text-paragraph {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .revolutionalize-h1-mobile {
    display: block;
    font-size: 1.2rem;
    color: #00d092;
    padding-bottom: 1rem;
    font-weight: bold;
  }
  .revolutionalize-text-mobile {
    display: block;
    text-align: center;
    font-size: 0.8rem;
  }
  .revolutionalize-image {
    margin-left: 0;
    text-align: center;
  }
  .socialmedia-text {
    font-size: 0.8rem;
    color: #00d092;
    padding-bottom: 1rem;
    /* border-bottom:1px solid white ; */
  }
  .socialmediaicons {
    padding-bottom: 3rem;
    /* border-bottom:1px solid white ; */
  }
  .socialmediaicon img {
    width: 10%;
  }
}
