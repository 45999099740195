.arrow-container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: fixed;
  bottom: 25px;
  right: 0;
}

.scrollbutn {
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 5px;
  position: relative;
}
.scrollbutn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  z-index: -1;
  animation: ripple 2s ease-out infinite;
}

.scrollbutn:first-child {
  background-color: #00d092;
}

.scrollbutn:first-child::before {
  animation-delay: 0.2s;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

i.fa.fa-angle-down,
i.fa.fa-angle-up {
  left: 30px;
  bottom: 25px;
  position: absolute;
}
