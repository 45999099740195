.reincarnate-text {
  padding-left: 18rem;
  padding-top: 8rem;
}

.reincarnate-h1-mobile {
  display: none;
}
.reincarnate-wrapper {
  background-color: #242424;
  /* padding-top: 10rem; */
  padding-bottom: 10rem;
}

.reincarnate-img {
  width: 120%;
}
.reincarnate-para-tablet {
  display: none;
}

@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .reincarnate-wrapper {
    /* padding-top: 19rem; */
    overflow-x: hidden !important;
  }
  .reincarnate-text {
    padding-left: 10rem;
    padding-top: 3rem;
  }
  .reincarnate-text p {
    font-size: 0.8rem;
    margin-bottom: auto;
  }
  .reincarnate-image-text {
    font-size: 0.6rem;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .reincarnate-wrapper {
    overflow-x: hidden !important;
  }
  .reincarnate-h1-laptop {
    color: #00d092;
  }
  .reincarnate-para-1 {
    display: none;
  }
  /* .reincarnate-para-tablet {
    font-size: 0.8rem;
    margin-bottom: 0rem;
    display: block;
  } */
  .reincarnate-para-tablet {
    font-size: 1rem;
    margin-bottom: 0rem;
    display: block;
  }
  .reincarnate-text {
    padding-left: 3rem;
    padding-top: 0rem;
  }
  .reincarnate-image-text {
    font-size: 0.7rem;
  }
  .reincarnate-img {
    width: 107%;
  }
}
@media only screen and (max-width: 767px) and (min-width: 375px) {
  .reincarnate-h1-laptop {
    display: none;
  }
  .reincarnate-img {
    width: 100%;
  }
  .reincarnate-h1-mobile {
    display: block;
    text-align: center;
    font-size: 1.2rem;
    color: #00d092;
    padding-bottom: 1rem;
    font-weight: bold;
  }
  .reincarnate-image-text {
    font-size: 0.6rem;
  }
  .reincarnate-text {
    padding-left: 0;
    text-align-last: center;
    font-size: 0.8rem;
  }
  .reincarnate-para-1 {
    margin-top: -8rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
  }
}
