.gray {
  text-decoration: none;
  color: #00d092;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 5vw;
  font-size: 1.2vw;
  border-bottom: 2px solid white;
  padding-bottom: 1rem;
}

.gray:hover {
  border-bottom: 2px solid #00d092;
  color: #ffff;
}

.remodelyourwebsite-wrapper {
  background-color: #242424;
  /* padding-top: 15rem; */
  padding-bottom: 10rem;
}

.remodelyourwebsite-text {
  margin-top: 5rem;
  text-align: justify;
  text-align-last: left;
  padding-left: 18rem;
  /* padding-left: 7rem; */
}

.remodel-paragraph-tablet {
  display: none;
}

.video-description {
  text-align: center !important;
  justify-content: center !important;
}

.remodel-h1-mobile {
  display: none;
}
.remodelyourwebsite-video video {
  width: 120%;
}
.requestademoform-link{
  font-weight: bold;
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  /* .remodelyourwebsite-wrapper {
    padding-top: 17rem;
  } */

  .remodelyourwebsite-text {
    margin-top: 3rem;
    padding-left: 10rem;
  }

  .remodelyourwebsite-text p {
    font-size: 0.8rem;
  }

  .video-description {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .remodelyourwebsite-text {
    padding-left: 10rem;
    margin-top: 1rem;
  }

  .remodel-paragraph-1 {
    display: none;
  }

  /* .remodel-paragraph-tablet {
    font-size: 0.8rem;
    margin-bottom: 0rem;
    display: block;
  } */
  .remodel-paragraph-tablet {
    font-size: 1rem;
    margin-bottom: 0rem;
    display: block;
  }

  .video-description {
    font-size: 0.7rem;
  }

  .remodel-h1-laptop {
    display: block;
    color: #00d092;
  }

  video {
    width: 107%;
  }
}
@media only screen and (max-width: 767px) and (min-width: 375px) {
  /* .remodelyourwebsite-wrapper {
    padding-top: 14rem;
  } */
  .remodel-h1-mobile {
    display: block;
    font-size: 1.2rem;
    color: #00d092;
    padding-bottom: 1.8rem;
    font-weight: bold;
  }
  .remodel-h1-laptop {
    display: none;
  }
  .remodelyourwebsite-video {
    text-align: center;
  }
  .remodelyourwebsite-text {
    text-align-last: center;
    padding-left: 0;
  }
  .remodelyourwebsite-video video {
    width: 100%;
  }
  .video-description {
    font-size: 0.6rem;
  }
  .remodel-paragraph-1 {
    font-size: 0.8rem;
    padding-bottom: 1rem;
    margin-top: -4rem;
  }
}
